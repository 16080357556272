import React from "react"
import { Link } from "gatsby"

import MainLayout from "../components/mainlayout"
import SEO from "../components/seo"

const SecondPage = () => (
  <MainLayout>

      <section class="recipes-section spad pt-0">
          <div class="container">
    <SEO title="Earnings Statement" />
    <h2>Earnings Statement</h2>
    <p>Last Updated on January 09, 2018

        This website is a collaborative work written by a group of individuals. This blog accepts forms of cash advertising, sponsorship, paid insertions or other forms of compensation.

        The compensation received may influence the advertising content, topics or posts made in this site. That content, advertising space or post may not always be identified on the page itself as paid or sponsored content.

        The owner(s) of this site is compensated to provide opinion on products, services, websites and various other topics. Even though the owner of this site receive(s) compensation for posts or advertisements, we always give our honest opinions, findings, beliefs, or experiences on those topics or products. The views and opinions expressed on this site are purely the content creators' own. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question.

        This site does not contain any content which might present a conflict of interest.</p>
    <Link to="/">Go back to the homepage</Link>
          </div>
      </section>
  </MainLayout>
)

export default SecondPage
